<template>
<div>
  <div class="d-flex justify-start aling-center" v-if="selectedCycle == -2">
    <date-picker class="mt-4" v-model="selectedDate"></date-picker>
    <div class="d-flex justify-start px-2 py-4">
      <div class="cycle-container d-flex justify-start align-center">
        <span class="cycle-item" @click="clickDay(0)" :class="numOfWeek == 1 ? 'active' : ''">周一</span>
        <span class="cycle-item" @click="clickDay(1)" :class="numOfWeek == 2 ? 'active' : ''">周二</span>
        <span class="cycle-item" @click="clickDay(2)" :class="numOfWeek == 3 ? 'active' : ''">周三</span>
        <span class="cycle-item" @click="clickDay(3)" :class="numOfWeek == 4 ? 'active' : ''">周四</span>
        <span class="cycle-item" @click="clickDay(4)" :class="numOfWeek == 5 ? 'active' : ''">周五</span>
        <span class="cycle-item" @click="clickDay(5)" :class="numOfWeek == 6 ? 'active' : ''">周六</span>
        <span class="cycle-item" @click="clickDay(6)" :class="numOfWeek == 0 ? 'active' : ''">周日</span>
      </div>
      <v-spacer></v-spacer>
    </div>
    <v-spacer></v-spacer>
    <div>

    </div>
  </div>
  <div class="d-flex justify-space-between align-start pt-4 px-1" v-if="selectedCycle == -1">
    <v-text-field placeholder="输入任务名称" outlined dense v-model="pendingTaskName"></v-text-field>
    <v-btn class="release-btn white--text" color="#58c583" @click="createPendingTask">发布</v-btn>
  </div>
  <div class="d-flex justify-start" v-if="selectedCycle == 0">
    <div class="d-flex justify-start px-2 py-4">
      <div class="cycle-container d-flex justify-start align-center">
        <span class="cycle-item" @click="clickCycle(0)" :class="currentCycle == 0 ? 'active' : ''">年度计划</span>
        <span class="cycle-item" @click="clickCycle(1)" :class="currentCycle == 1 ? 'active' : ''">第一季度</span>
        <span class="cycle-item" @click="clickCycle(2)" :class="currentCycle == 2 ? 'active' : ''">第二季度</span>
        <span class="cycle-item" @click="clickCycle(3)" :class="currentCycle == 3 ? 'active' : ''">第三季度</span>
        <span class="cycle-item" @click="clickCycle(4)" :class="currentCycle == 4 ? 'active' : ''">第四季度</span>
      </div>
      <v-spacer></v-spacer>
    </div>
  </div>
  <div class="d-flex justify-start" v-if="selectedCycle >= 1">
    <div class="d-flex justify-start px-2 py-4">
      <div class="cycle-container d-flex justify-start align-center">
        <span class="cycle-item" @click="clickWeek(0)" :class="currentWeek == 0 ? 'active' : ''">{{selectedCycle}}月</span>
        <span class="cycle-item" @click="clickWeek(1)" :class="currentWeek == 1 ? 'active' : ''">第1周</span>
        <span class="cycle-item" @click="clickWeek(2)" :class="currentWeek == 2 ? 'active' : ''">第2周</span>
        <span class="cycle-item" @click="clickWeek(3)" :class="currentWeek == 3 ? 'active' : ''">第3周</span>
        <span class="cycle-item" @click="clickWeek(4)" :class="currentWeek == 4 ? 'active' : ''">第4周</span>
        <span class="cycle-item" @click="clickWeek(5)" :class="currentWeek == 5 ? 'active' : ''">第5周</span>
      </div>
      <v-spacer></v-spacer>
    </div>
  </div>
  <div class="create-task my-4">
    <v-btn v-if="selectedCycle!==-1" color="rgb(40, 121, 255)" class="white--text mx-2" @click="openCreateTask()">创建任务</v-btn>
  </div>
  <div class="action-container d-flex justify-space-between align-center">
    <div class="filters d-flex justify-start align-center">
      <div class="mr-4">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              状态<v-icon style="font-size: 18px;">mdi-menu-down</v-icon>
            </span>
          </template>
          <v-list>
            <v-list-item class="status-container">
              <div class="status-item">
                <v-checkbox @click="stopEvent" @change="" v-model="statusAllCheck" label="全部状态"></v-checkbox>
              </div>
              <div v-for="(st, index) in this.status_list" class="status-item pl-4">
                <v-checkbox @click="stopEvent" @change="" v-model="st.checked" :label="st.label"></v-checkbox>
              </div>
            </v-list-item>
            <v-list-item class="mt-4">
              <v-btn color="#1B6CF1" class="white--text" @click="setStatusFilter">确定</v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div v-if="this.mode=='mine'" class="mr-4">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              范围<v-icon style="font-size: 18px;">mdi-menu-down</v-icon>
            </span>
          </template>
          <v-list>
            <v-list-item class="status-container">
              <div class="status-item">
                <v-checkbox @click="stopEvent" @change="ev => rangeFilterChange(ev, 0)" v-model="rangeAll" label="全部范围"></v-checkbox>
              </div>
              <div class="status-item pl-4">
                <v-checkbox @click="stopEvent" @change="ev => rangeFilterChange(ev, 1)" v-model="rangeResponsible" label="我负责的"></v-checkbox>
              </div>
              <div class="status-item pl-4">
                <v-checkbox @click="stopEvent" @change="ev => rangeFilterChange(ev, 2)" v-model="rangeDistributed" label="我派发的"></v-checkbox>
              </div>
              <div class="status-item pl-4">
                <v-checkbox @click="stopEvent" @change="ev => rangeFilterChange(ev, 3)" v-model="rangeParticipated" label="我参与的"></v-checkbox>
              </div>
            </v-list-item>
            <v-list-item class="mt-4">
              <v-btn color="#1B6CF1" class="white--text" @click="setRangeFilter">确定</v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <div v-if="this.mode=='superior'" class="mr-4">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              人员<v-icon style="font-size: 18px;">mdi-menu-down</v-icon>
            </span>
          </template>
          <v-list>
            <v-list-item class="status-container">
              <div class="status-item">
                <v-checkbox @click="stopEvent" v-model="superiorAll" label="全部人员"></v-checkbox>
              </div>
              <div v-for="(sUser, index) in this.superior_list" class="status-item pl-4">
                <v-checkbox @click="stopEvent" @change="" v-model="sUser.checked" :label="sUser.employeeName"></v-checkbox>
              </div>
            </v-list-item>
            <v-list-item class="mt-4">
              <v-btn color="#1B6CF1" class="white--text" @click="setSuperiorFilter">确定</v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              系统默认排序<v-icon style="font-size: 18px;">mdi-menu-down</v-icon>
            </span>
          </template>
          <v-list>
            <v-list-item class="status-container">
              <div class="status-item" @click="sortTasksBy('default')" :class="sortBy.default == 1 ? 'blue--text' : ''">
                系统默认排序
              </div>
              <div class="status-item my-2 d-flex justify-start" @click="sortTasksBy('startDate')">
                <span :class="sortBy.startDate != 0 ? 'blue--text' : ''">开始时间</span>
                <span style="display: block;" class="ml-4">
                  <v-icon style="display: block;font-size: 14px;margin-top:1px;" :class="sortBy.startDate == 1 ? 'blue--text' : ''">mdi-chevron-up</v-icon>
                  <v-icon style="display: block;font-size: 14px; margin-top: -5px;" :class="sortBy.startDate == 2 ? 'blue--text' : ''">mdi-chevron-down</v-icon>
                </span>
              </div>
              <div class="status-item my-2 d-flex justify-start" @click="sortTasksBy('endDate')">
                <span :class="sortBy.endDate != 0 ? 'blue--text' : ''">结束时间</span>
                <span style="display: block;" class="ml-4">
                  <v-icon style="display: block;font-size: 14px;margin-top:1px;" :class="sortBy.endDate == 1 ? 'blue--text' : ''">mdi-chevron-up</v-icon>
                  <v-icon style="display: block;font-size: 14px; margin-top: -5px;" :class="sortBy.endDate == 2 ? 'blue--text' : ''">mdi-chevron-down</v-icon>
                </span>
              </div>
              <div class="status-item my-2 d-flex justify-start" @click="sortTasksBy('created')">
                <span :class="sortBy.created != 0 ? 'blue--text' : ''">创建时间</span>
                <span style="display: block;" class="ml-4">
                  <v-icon style="display: block;font-size: 14px;margin-top:1px;" :class="sortBy.created == 1 ? 'blue--text' : ''">mdi-chevron-up</v-icon>
                  <v-icon style="display: block;font-size: 14px; margin-top: -5px;" :class="sortBy.created == 2 ? 'blue--text' : ''">mdi-chevron-down</v-icon>
                </span>
              </div>
              <div class="status-item my-2 d-flex justify-start" @click="sortTasksBy('priority')" :class="sortBy.priority != 0 ? 'blue--text' : ''">
                按优先级排序
              </div>
            </v-list-item>
            <!-- <v-list-item class="mt-4">
                            <v-btn color="#1B6CF1" class="white--text">记住排序</v-btn>
                        </v-list-item> -->
          </v-list>
        </v-menu>
      </div>
    </div>
    <v-spacer></v-spacer>

    <div v-if="this.selectedCycle>=-1" class="actions">
      <v-btn class="ma-2 mr-4" color="primary" @click="onTaskGantt" outlined>甘特图</v-btn>
      <v-btn class="ma-2" color="primary" @click="onTaskPrint" outlined>打印与导出</v-btn>
    </div>

  </div>
  <div class="task-list mt-6">
    <span style="display: none;">{{isToday}}{{cycleValue}}</span>

    <div class="current-task-list py-6" v-if="tasks.length != 0">
      <div class="task-item" :class="currentOpen ? 'expansion-show' : 'expansion-hide'" v-for="task_item in pendingTaskList">
        <TaskComponent :task_item="task_item" />
      </div>
    </div>

    <div class="expired-task-list py-6" v-if="tasks.length != 0">
      <div class="line"></div>
      <div class="mb-4 expired-icon d-flex justify-center align-center" @click="toggleExpiredExpansion">
        <span>已过期</span>
        <v-icon style="font-size: 14px;color: #fff;">{{expiredOpen ? 'mdi-chevron-double-up' : 'mdi-chevron-double-down'}}</v-icon>
      </div>
      <div class="task-item" :class="expiredOpen ? 'expansion-show' : 'expansion-hide'" v-for="task_item in expiredTaskList">
        <TaskComponent type="expired" @getAgainTasks="getAgainTasks()" :task_item="task_item" @openEdit="openEdit" />
      </div>
    </div>

    <div class="current-task-list mt-12" v-if="tasks.length != 0">
      <div class="line"></div>
      <div class="mb-4 current-icon d-flex justify-center align-center" @click="toggleCurrentExpansion">
        <span>当前任务</span>
        <v-icon style="font-size: 14px;color: #fff;">{{currentOpen ? 'mdi-chevron-double-up' : 'mdi-chevron-double-down'}}</v-icon>
      </div>
      <div class="task-item" :class="currentOpen ? 'expansion-show' : 'expansion-hide'" v-for="task_item in currentTaskList">
        <TaskComponent @getAgainTasks="getAgainTasks()" :task_item="task_item" @openEdit="openEdit" />
      </div>
    </div>

    <div class="no-list" v-if="tasks.length == 0">
      暂无数据
    </div>
  </div>
  <div class="mt-7" v-if="this.selectedCycle>-2">
    <v-tabs v-model="tab" icons-and-text height="36" change="">
      <v-tabs-slider></v-tabs-slider>
      <v-tab href="#tab-1" @click="">
        <span>
          <span>评价沟通</span>
        </span>
      </v-tab>
      <v-tab href="#tab-2" @click="">
        <span>
          <span>工作总结</span>
        </span>
      </v-tab>
      <v-tab href="#tab-3" @click="">
        <span>
          <span>操作历史</span>
        </span>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" class="pt-2">
      <v-tab-item id="tab-1">
        <Intercom :ic_obj="{ic_parent:getTaskIntercomId, ic_parent_type:16}" />
      </v-tab-item>
      <v-tab-item id="tab-2">
        <Intercom :ic_obj="{ic_parent:getTaskIntercomId, ic_parent_type:20}" />
      </v-tab-item>
      <v-tab-item id="tab-3">

      </v-tab-item>
    </v-tabs-items>
  </div>
  <CreateTask ref="createTask" @save="createTask" />
  <!-- <Reply /> -->
</div>
</template>

<script>
// import CreateTask from '@/components/schedule/CreateTask.vue';
import Intercom from "@/components/common/Intercom/Intercom.vue";
import {
  Routes
} from "@/constants/routes.js";
import {
  constant
} from "@/constants/constant.js";
import TaskComponent from '@/components/schedule/shared/TaskComponent.vue';
import Reply from '@/components/schedule/Reply.vue';
import {
  mapGetters,
  mapActions,
  mapState
} from 'vuex';
import {
  getUser
} from '@/utils/authUser.service';
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/zh-cn';
import 'vue2-datepicker/index.css';
export default {
  name: 'TabFirstComponent',
  components: {
    Intercom,
    TaskComponent,
    Reply,
    CreateTask: () => import('@/components/common/CreateTask.vue'),
    DatePicker,
  },
  props: ['selectedCycle', 'selectedYear', 'mode'],
  data() {
    return {
      menu: false,
      // main letiable
      cycle: -1,
      expiredOpen: true,
      currentOpen: true,
      statusAllCheck: true,
      statusNotStarted: true,
      statusProcessing: false,
      statusCompleted: false,
      statusDelayed: false,
      statusCancelled: false,
      statusPaused: false,
      rangeAll: true,
      superiorAll: true,
      rangeResponsible: true,
      rangeDistributed: false,
      rangeParticipated: false,
      task_status: "",
      currentCycle: 0,
      currentWeek: 0,
      selectedDate: null,
      filter: {
        // task_owner: getUser().id
      },
      sortBy: {
        default: 0,
        startDate: 0,
        endDate: 0,
        created: 0,
        priority: 0
      },
      pendingTaskName: '',
      superior_list: [],
      status_list: [],
      tab: null,
      numOfWeek: null,
      today: null,
    }
  },
  watch: {
    superiorAll(v) {
      this.superior_list.map((item) => {
        item.checked = v;
      });
    },
    statusAllCheck(v) {
      this.status_list.map((item) => {
        item.checked = v;
      });
    },
  },
  computed: {
    // ...mapGetters('task', ['tasks']),
    ...mapState('task', ['tasks']),
    ...mapGetters("auth", ["authUser"]),
    ...mapGetters("user", ["taskUsers"]),
    getTaskIntercomId() {
      return this.selectedYear * 1000 + (this.selectedCycle == 0 ? this.currentCycle * 50 : 0) + this.selectedCycle * 10 + this.currentWeek;
    },
    pendingTaskList: function () {
			
      if (this.tasks.length != 0) {
        let tmp = [];
        this.tasks.map(t => {
          if (t.task_start_date == null && t.task_end_date == null) {
            tmp.push(t)
          }
        });
        return tmp;
      } else {
        return [];
      }
    },
    expiredTaskList: function () {
			this.tasks;
      if (this.tasks.length == 0) return [];
      let temp = [];
      temp = this.tasks.filter(task => {
        let taskEnd = new Date(task.task_end_date).getTime();
        let today = new Date().getTime();
        if (taskEnd <= today) {
          return task;
        }
      });
      return temp;
    },
    currentTaskList: function () {
			this.tasks;
      if (this.tasks.length == 0) return [];
      let temp = [];
      temp = this.tasks.filter(task => {
        let taskEnd = new Date(task.task_end_date).getTime();
        let today = new Date().getTime();
        if (taskEnd >= today) {
          return task;
        }
      });
      return temp;
    },
    isToday: function () {
      const today = moment(new Date()).format('YYYY-MM-DD');
      const day = moment(this.selectedDate).format('YYYY-MM-DD');
      return today == day;
    },
    currentDate: {
      get: function () {
        if (this.selectedDate != '') {
          let date = new Date(this.selectedDate + ' 00:00');
          date.setFullYear(this.selectedYear);
          return this.dateFormatter(date);
          // return this.selectedDate;
        } else {
          // return this.dateFormatter(today);
        }
      },
      set: function (newValue) {
        this.selectedDate = newValue;
      }
    },
    cycleValue: function () {
      if (this.selectedCycle == -1) {
        this.setStartEndDateOfMonthAsFilter('null', 'null')
      }
      if (this.selectedCycle == 0) {
        let start = this.selectedYear + constant.cycleWithDates[this.currentCycle].startDate;
        let end = this.selectedYear + constant.cycleWithDates[this.currentCycle].endDate;
        this.setStartEndDateOfMonthAsFilter(new Date(start), new Date(end));
        return this.currentCycle;
      } else if (this.selectedCycle >= 1) {
        if (this.currentWeek == 0) {
          let date = new Date();
          date.setFullYear(this.selectedYear);
          let y = date.getFullYear();
          let m = this.selectedCycle;
          let firstDay = new Date(y, m - 1, 1);
          let lastDay = new Date(y, m, 0);
          this.setStartEndDateOfMonthAsFilter(firstDay, lastDay);
          return this.selectedCycle;
        }
        if (this.currentWeek == 1) {
          let date = new Date();
          date.setMonth(this.selectedCycle - 1);
          let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
          let lastDay = new Date(date.getFullYear(), date.getMonth(), 1 + 6);
          this.setStartEndDateOfMonthAsFilter(firstDay, lastDay);
          return this.selectedCycle;
        }
        if (this.currentWeek == 2) {
          let date = new Date();
          date.setMonth(this.selectedCycle - 1);
          let firstDay = new Date(date.getFullYear(), date.getMonth(), 8);
          let lastDay = new Date(date.getFullYear(), date.getMonth(), 8 + 6);
          this.setStartEndDateOfMonthAsFilter(firstDay, lastDay);
          return this.selectedCycle;
        }
        if (this.currentWeek == 3) {
          let date = new Date();
          date.setMonth(this.selectedCycle - 1);
          let firstDay = new Date(date.getFullYear(), date.getMonth(), 15);
          let lastDay = new Date(date.getFullYear(), date.getMonth(), 15 + 6);
          this.setStartEndDateOfMonthAsFilter(firstDay, lastDay);
          return this.selectedCycle;
        }
        if (this.currentWeek == 4) {
          let date = new Date();
          date.setMonth(this.selectedCycle - 1);
          let firstDay = new Date(date.getFullYear(), date.getMonth(), 22);
          let lastDay = new Date(date.getFullYear(), date.getMonth(), 22 + 6);
          this.setStartEndDateOfMonthAsFilter(firstDay, lastDay);
          return this.selectedCycle;
        }
        if (this.currentWeek == 5) {
          let date = new Date();
          date.setMonth(this.selectedCycle - 1);
          let firstDay = new Date(date.getFullYear(), date.getMonth(), 29);
          let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
          this.setStartEndDateOfMonthAsFilter(firstDay, lastDay);
          return this.selectedCycle;
        }
      } else {
        return '';
      }
    }
  },
  created() {
    Object.assign(this.filter, {
      task_status: '1,2,3,4,5,6'
    });
    this.status_list = constant.taskStatus.map((item) => {
      return {
        status_id: item.value,
        label: item.text,
        checked: true,
      };
    });
    this.settingStatusFilter();

    if (this.mode == "mine") {
      Object.assign(this.filter, {
        task_owner: getUser().id
      });
    }

    this.getTaskUsers({
      user_superior: this.authUser.id
    }).then((res) => {
      this.superior_list = this.taskUsers.map((item) => {
        return {
          user_id: item.id,
          employeeName: item.employeeName,
          checked: true,
        };
      });

      this.superior_list.push({
        user_id: this.authUser.id,
        employeeName: this.authUser.employeeName,
        checked: true,
      })

      if (this.mode == "superior") {
        Object.assign(this.filter, {
          task_owners: this.superior_list.map((item) => {
            return item.checked ? item.user_id : null
          }).join(',')
        });
      }

      this.getTasksByFilter();
    });

  },
  mounted() {
    const today = new Date();
    this.numOfWeek = today.getDay();
    this.selectedDate = today;
    if (this.selectedCycle == -2 && this.selectedYear == today.getFullYear()) {
      // this.setStartEndDateOfMonthAsFilter();
    }
    // set current quater
    this.currentCycle = this.getQuarter();
    // get objects by owner
    this.getFilteredOkrs({
      filter: {
        ob_owner: getUser().id,
        ob_participant: getUser().id
      }
    });
    // get items by owner
    this.getItems({
      item_owner: getUser().id,
      item_participant: getUser().id
    });
  },
  methods: {
    ...mapActions('task', ['getTasks', 'addTask']),
    ...mapActions("okr", ["getFilteredOkrs"]),
    ...mapActions("item", ['getItems']),
    ...mapActions("user", ['getTaskUsers']),
		onTaskGantt(){
			this.$router.push({
        path: Routes.TASK_GANTT_PAGE + '?filter=' + JSON.stringify(this.filter)
      });
			// let route = this.$router.resolve({
      //   path: Routes.TASK_GANTT_PAGE + '?filter=' + JSON.stringify(this.filter),
      // });
      // window.open(route.href, '_self');
		},
    onTaskPrint() {
      let route = this.$router.resolve({
        path: Routes.TASK_PRINT_PAGE + '?filter=' + JSON.stringify(this.filter),
      });
      window.open(route.href, '_blank');
    },
    createPendingTask() {
      this.addTask({
        task_approver: null,
        task_collaborator: "",
        task_creator: getUser().id,
        task_description: "",
        task_end_date: null,
        task_est_worktime: null,
        task_name: this.pendingTaskName,
        task_owner: getUser().id,
        task_priority: 3,
        task_start_date: null,
        task_target_price: null,
        task_unit: 1,
        task_vice_leader: null,
      })
      this.pendingTaskName = '';
      this.getTasksByFilter();
    },
    openEdit(task) {
      this.$emit('openEdit', task)
    },
    sortTasksBy(param) {
      if (param == 'default') {
        Object.assign(this.sortBy, {
          endDate: 0
        }, {
          created: 0
        }, {
          priority: 0
        });
        Object.assign(this.sortBy, {
          default: this.sortBy.default == 0 ? 1 : 0
        });
        delete this.filter.task_sortby;
      }
      if (param == 'startDate') {
        if (this.sortBy.startDate == 0) {
          Object.assign(this.sortBy, {
            default: 0
          }, {
            endDate: 0
          }, {
            created: 0
          }, {
            priority: 0
          });
          Object.assign(this.sortBy, {
            startDate: 1
          });
          Object.assign(this.filter, {
            task_sortby: 'task_start_date ASC'
          })
        } else {
          Object.assign(this.sortBy, {
            default: 0
          }, {
            endDate: 0
          }, {
            created: 0
          }, {
            priority: 0
          });
          Object.assign(this.sortBy, {
            startDate: this.sortBy.startDate == 1 ? 2 : 1
          });
          Object.assign(this.filter, {
            task_sortby: 'task_start_date DESC'
          })
        }
      }
      if (param == 'endDate') {
        if (this.sortBy.endDate == 0) {
          Object.assign(this.sortBy, {
            endDate: 1
          });
          Object.assign(this.sortBy, {
            default: 0
          }, {
            startDate: 0
          }, {
            created: 0
          }, {
            priority: 0
          });
          Object.assign(this.filter, {
            task_sortby: 'task_end_date ASC'
          })
        } else {
          Object.assign(this.sortBy, {
            default: 0
          }, {
            startDate: 0
          }, {
            created: 0
          }, {
            priority: 0
          });
          Object.assign(this.sortBy, {
            endDate: this.sortBy.endDate == 1 ? 2 : 1
          });
          Object.assign(this.filter, {
            task_sortby: 'task_end_date DESC'
          })
        }
      }
      if (param == 'created') {
        if (this.sortBy.created == 0) {
          Object.assign(this.sortBy, {
            default: 0
          }, {
            startDate: 0
          }, {
            endDate: 0
          }, {
            priority: 0
          });
          Object.assign(this.sortBy, {
            created: 1
          });
          Object.assign(this.filter, {
            task_sortby: 'created_at ASC'
          })
        } else {
          Object.assign(this.sortBy, {
            default: 0
          }, {
            startDate: 0
          }, {
            endDate: 0
          }, {
            priority: 0
          });
          Object.assign(this.sortBy, {
            created: this.sortBy.created == 1 ? 2 : 1
          });
          Object.assign(this.filter, {
            task_sortby: 'created_at DESC'
          })
        }
      }
      if (param == 'priority') {
        Object.assign(this.sortBy, {
          default: 0
        }, {
          startDate: 0
        }, {
          endDate: 0
        }, {
          created: 0
        });
        Object.assign(this.sortBy, {
          priority: this.sortBy.priority == 0 ? 1 : 0
        });
        Object.assign(this.filter, {
          task_sortby: 'priority DESC'
        })
      }
      this.getTasksByFilter();
    },
    createTask(e) {
      this.newTask = e
      this.taskMenu = false
      this.addTask(this.newTask).then(res=>{
				this.getTasksByFilter();
			})
    },
    openCreateTask() {
      if (this.selectedCycle == -2) {
        this.$refs.createTask.open({
          task_start_date: moment(this.selectedDate).format('YYYY-MM-DD') + ' 08:00:00',
          task_end_date: moment(this.selectedDate).format('YYYY-MM-DD') + ' 18:00:00'
        });
      } else {
        this.$refs.createTask.open({
          task_start_date: moment(this.filter.task_start_date).format('YYYY-MM-DD') + ' 08:00:00',
          task_end_date: moment(this.filter.task_end_date).format('YYYY-MM-DD') + ' 18:00:00'
        });
      }
    },
    clickWeek(param) {
      this.currentWeek = param;
    },
    clickCycle(param) {
      this.currentCycle = param;
    },
    setStartEndDateOfMonthAsFilter(start = '', end = '') {
      // get start date and end date of current month
      let date = new Date();
      let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      if (start == '') {
        Object.assign(this.filter, {
          task_start_date: firstDay
        }, {
          task_end_date: lastDay
        });
      } else {
        Object.assign(this.filter, {
          task_start_date: start
        }, {
          task_end_date: end
        });
      }
      this.getTasksByFilter();
    },
    dateFormatter(date) {
      return date.getFullYear() + '-' + (date.getMonth() < 9 ? ('0' + (date.getMonth() + 1)) : date.getMonth() + 1) + '-' + ('0' + date.getDate()).slice(-2)
    },
    // pick date by day of week
    clickDay(pickedDay) {
      const day = this.getCurrentDateFromDay(pickedDay);
      this.selectedDate = day;
      this.numOfWeek = day.getDay();
      Object.assign(this.filter, {
        task_start_date: moment(this.selectedDate).format("YYYY-MM-DD")
      }, {
        task_end_date: moment(this.selectedDate).format("YYYY-MM-DD")
      });
      this.getTasksByFilter();
    },
    getCurrentDateFromDay(pickedDay) {
      const today = new Date();
      const weekDay = today.getDay() == 0 ? 7 : today.getDay();
      const first = new Date(today.getFullYear(), today.getMonth(), today.getDate() - weekDay + 1);
      return new Date(first.getFullYear(), first.getMonth(), first.getDate() + pickedDay);
    },
    pickUser: function () {
      return this.date;
    },
    toggleExpiredExpansion: function () {
      this.expiredOpen = !this.expiredOpen;
    },
    toggleCurrentExpansion: function () {
      this.currentOpen = !this.currentOpen;
    },
    getQuarter: function () {
      let today = new Date();
      let m = Math.floor(today.getMonth() / 3) + 1;
      return m > 4 ? m - 4 : m;
    },
    getWeek(date) {
      let monthStart = new Date(date);
      monthStart.setDate(0);
      let offset = (monthStart.getDay() + 1) % 7 - 1; // -1 is for a week starting on Monday
      return Math.ceil((date.getDate() + offset) / 7);
    },
    setStatusFilter: function (ev) {
      // ev.stopPropagation()
      this.settingStatusFilter();

      this.getTasksByFilter();
    },
    settingStatusFilter: function () {
      Object.assign(this.filter, {
        task_status: this.status_list.map((item) => {
          return item.checked ? item.status_id : null
        }).join(',')
      });
    },
    stopEvent: function (ev) {
      ev.stopPropagation();
    },
    rangeFilterChange: function (ev, param) {
      if (param == 0) {
        if (ev) {
          this.rangeResponsible = true;
          this.rangeDistributed = true;
          this.rangeParticipated = true;
          Object.assign(this.filter, {
            task_owner: getUser().id
          }, {
            task_vice_leader: getUser().id
          }, {
            task_collaborator: getUser().id
          });
        } else {
          this.rangeResponsible = false;
          this.rangeDistributed = false;
          this.rangeParticipated = false;
          delete this.filter.task_owner;
          delete this.filter.task_vice_leader;
          delete this.filter.task_collaborator;
        }
      }
      if (param == 1) {
        this.rangeAll = this.rangeResponsible && this.rangeDistributed && this.rangeParticipated;
        if (ev) {
          Object.assign(this.filter, {
            task_owner: getUser().id
          })
        } else {
          delete this.filter.task_owner;
        }
      }
      if (param == 2) {
        this.rangeAll = this.rangeResponsible && this.rangeDistributed && this.rangeParticipated;
        if (ev) {
          Object.assign(this.filter, {
            task_vice_leader: getUser().id
          })
        } else {
          delete this.filter.task_vice_leader;
        }
      }
      if (param == 3) {
        this.rangeAll = this.rangeResponsible && this.rangeDistributed && this.rangeParticipated;
        if (ev) {
          Object.assign(this.filter, {
            task_collaborator: getUser().id
          })
        } else {
          delete this.filter.task_collaborator;
        }
      }
    },
    setRangeFilter(ev) {
      // ev.stopPropagation();
      this.getTasksByFilter();
    },
    setSuperiorFilter() {
      Object.assign(this.filter, {
        task_owners: this.superior_list.map((item) => {
          return item.checked ? item.user_id : null
        }).join(',')
      });
      this.getTasksByFilter();
    },
    getTasksByFilter() {
      this.getTasks(this.filter);
    },
    selectDate() {
      this.$refs.menu.save(this.selectedDate);
    },
    getAgainTasks(ev) {
      this.getTasksByFilter();
    }
  }
}
</script>

<style scoped>
.cycle-item {
  color: #647481;
  display: block;
  min-width: 42px;
  text-align: center;
  background-color: #ddd;
  border-radius: 20px;
  margin-left: 8px;
  margin-right: 8px;
  padding: 5px 10px;
}

.cycle-item:hover {
  cursor: pointer;
}

.no-list {
  width: 100%;
  border: 1px dashed #ddd;
  text-align: center;
  padding: 30px 0px;
  font-size: 24px;
  color: #ddd;
}

.expired-icon {
  background-color: #f68a87;
  cursor: pointer;
  border-radius: 10px;
  padding: 0 21px;
  width: 120px;
  height: 21px;
  display: inline-block;
  color: #fff;
  font-size: 12px;
  line-height: 21px;
  margin: 0 auto;
}

.current-icon {
  background: #c5c5c5;
  cursor: pointer;
  border-radius: 10px;
  padding: 0 21px;
  width: 120px;
  height: 21px;
  display: inline-block;
  color: #fff;
  font-size: 12px;
  line-height: 21px;
  margin: 0 auto;
}

.expansion-hide {
  display: none;
}

.expansion-show {
  display: block;
}

.line {
  border-top: 1px solid #e4ebf0;
  width: 100%;
  margin-bottom: -12px;
}

.active {
  background-color: #2879FF !important;
  color: #fff;
}

.status-container {
  display: block;
}

.status-item {
  max-height: 18px;
}

.release-btn {
  margin-left: -72px;
  margin-top: 1.5px;
  margin-right: 2px;
}

.status-item:hover {
  cursor: pointer;
}

.blue--text {
  color: rgb(40, 121, 255);
}
</style>
